
export const xAccessCode = {
   oneOf: 0,
   master: 1,
   admin:  2,
   chief:  3
};

export const xNullAddress =  '0x0000000000000000000000000000000000000000';
export const xUSDTAddress = {
  1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  5: '0x5375EC7492374e2B67BA295506eF38D69C8f992b'
}
export const xUSDZAddress = {
  1: '0xE4b70F9DdBb286f3cFA836E0fD970479f3193252',
  5: '0x0DaEBb0941E09374B45675374aa24023B461360E'
}; //main chain only

export const xOpCode = {
   block: 0x111,
   unBlock: 0x222,
   check: 0x333,
   protect: 0x666,
   txBlock: 0x777,
   txPay: 0x123
};



export const xBalance = {
  isNoticed: 0,
  protected: 1,
  erc20: 2,
  blocked: 3,
  summarized: 4,
  summarizedAPY: 5,
  summarizedTotal: 6,
  summarizedTotalAPY: 7,
  rn: 8,
  lastBlockingTime: 9,
  digSum: 10,
  digQual: 11,
  digQualChildCount: 12,
  digSumChild: 13,
  authorized: 14,
  sale: 15,
  digSumCapped: 16,
  digSumHodled: 17,
  digFactor: 18,
  payCount: 19,
  lastEarningTime: 20
}

export const xBalance_old = {
  erc20: 0,
  blocked: 1,
  summarized: 2,
  summarizedAPY: 3,
  summarizedTotal: 4,
  summarizedTotalAPY: 5,
  digSum: 6,
  authorized: 7,
  sale: 8,
  digQual: 9,
  digQualChildCount: 10,
  digSumChild: 11,
  lastBlockingTime: 12,
  protected: 13,
  rn: 14,
  isNoticed: 15
};


export const xTotal = {
 supplyERC20: 0,
 supplyBlocked: 1,
 summarized: 2,
 totalSummarizedAPY: 3,
 totalDigged: 4,
 capped: 5,
 hodled: 6
};
